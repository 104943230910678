// extracted by mini-css-extract-plugin
export var root = "PlasmicPrice-module--root--31e3W";
export var freeBox___9Z5Vo = "PlasmicPrice-module--freeBox___9Z5Vo--2VYUZ";
export var freeBox__dDLfU = "PlasmicPrice-module--freeBox__dDLfU--1BKHe";
export var header = "PlasmicPrice-module--header--1Xr6l";
export var freeBox__hhz4S = "PlasmicPrice-module--freeBox__hhz4S--1UMAy";
export var link = "PlasmicPrice-module--link--2Bflc";
export var img = "PlasmicPrice-module--img--1CCL9";
export var __wab_imgSpacer = "PlasmicPrice-module--__wab_img-spacer--1F1nR";
export var text__mShoy = "PlasmicPrice-module--text__mShoy--28mAL";
export var freeBox__mRNv = "PlasmicPrice-module--freeBox__mRNv--15Y5U";
export var text__qAtch = "PlasmicPrice-module--text__qAtch--3ye7A";
export var text__svgBe = "PlasmicPrice-module--text__svgBe--1bZoZ";
export var priceList = "PlasmicPrice-module--priceList--2sCW5";
export var freeBox__xnK0P = "PlasmicPrice-module--freeBox__xnK0P--2T_Mt";
export var text__fO3G = "PlasmicPrice-module--text__fO3G--26fP8";
export var freeBox___4P5Bd = "PlasmicPrice-module--freeBox___4P5Bd--zz1S_";
export var freeBox__ezYy5 = "PlasmicPrice-module--freeBox__ezYy5--zdp4w";
export var text__zc6UV = "PlasmicPrice-module--text__zc6UV--ElI3s";
export var nationSelection = "PlasmicPrice-module--nationSelection--yliKS";
export var freeBox__xQ1IH = "PlasmicPrice-module--freeBox__xQ1IH--3uS2Y";
export var text__q6IR = "PlasmicPrice-module--text__q6IR--2YGzj";
export var freeBox__zsf0V = "PlasmicPrice-module--freeBox__zsf0V--3Bpxh";
export var weightNumber = "PlasmicPrice-module--weightNumber--b9CR8";
export var feeCalculate = "PlasmicPrice-module--feeCalculate--6B8dg";
export var freeBox__ipDik = "PlasmicPrice-module--freeBox__ipDik--2Dero";
export var freeBox__iZwTe = "PlasmicPrice-module--freeBox__iZwTe--dCPfO";
export var text___94XqR = "PlasmicPrice-module--text___94XqR--3eZqo";
export var calculateResult = "PlasmicPrice-module--calculateResult--3Ccd_";
export var priceList3 = "PlasmicPrice-module--priceList3--2esSd";
export var freeBox___5574E = "PlasmicPrice-module--freeBox___5574E--3ZQzj";
export var freeBox__cA7K0 = "PlasmicPrice-module--freeBox__cA7K0--1a0Yk";
export var text__ux227 = "PlasmicPrice-module--text__ux227--3cGit";
export var freeBox__pk0Ek = "PlasmicPrice-module--freeBox__pk0Ek--RdHf2";
export var text___2RXl = "PlasmicPrice-module--text___2RXl--3ifgd";
export var hkPrice = "PlasmicPrice-module--hkPrice--4vBCx";
export var freeBox__p9F3R = "PlasmicPrice-module--freeBox__p9F3R--zOuS9";
export var text__zjjB3 = "PlasmicPrice-module--text__zjjB3--1RZBj";
export var moPrice = "PlasmicPrice-module--moPrice--36qRI";
export var freeBox__y6Tos = "PlasmicPrice-module--freeBox__y6Tos--1Rnqc";
export var text___1S8Ym = "PlasmicPrice-module--text___1S8Ym--cqoed";
export var sgPrice = "PlasmicPrice-module--sgPrice--2tvZa";