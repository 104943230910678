import React, { useState } from 'react';
import { PlasmicPrice } from "../components/plasmic/gopanda/PlasmicPrice";
import { Table,Form,  Radio } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { Helmet } from "react-helmet"

function Price() {
    
  //做一個整體的state
  const [allValues, setAllValues] = useState({
    
    nation:'HK',
    price:{
            'JP':{basic:420,
                  add:60  
                },
            'SG':{basic:360,
                  add:75  
                },
            'HK':{basic:150,
                  add:60  
                },
            'MO':{basic:230,
                  add:60  
                  },
            '西MY':{basic:270,
                  add:110  
                  },
            '東MY':{basic:350,
                  add:150  
          },  
}

  });

 
  
  //一般改內容改state用這個
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value })
    //console.log(allValues)
  }

   //如果不是plasmic創造的元素，都要透過這個方法，才可以丟入額外變數
   const nationChange = (e,{ value }) => {
    setAllValues({ ...allValues, 
                   nation: value,
                   calculateResult:'',
                   weightNumber:'',
                })
  }

  //key是快遞單號
  //這裡我要把計價模型寫在這裡
  const feeCalculateHandler=()=>{
    let nation=allValues.nation
    let weight=allValues.weightNumber
    //下面我先把0.5進位的公斤算法準備好
    // if(nation==='MY'){
    //     let zipCode=allValues.customerZipCode.slice(0,2)
    //     let eastCode=['87','88','89','90','91','93','94','95','96','97','98']
    //     if(eastCode.includes(zipCode)){
    //       nation='東MY'
    //     }else{
    //       nation='西MY'
    //     }
    // }

   
    let weightBefore=Math.trunc(weight)
    if(weight-weightBefore>0.5 &&  weight>1){
           weight=weightBefore+1
    }else if(weight-weightBefore<0.5 && weight-weightBefore !==0 &&  weight>0.5){
           weight=weightBefore+0.5
    }else if(weight<=0.5){
      weight=0.5
    }

    
    
    //因為半公斤計價，所以我要算一下有幾個半公斤
    let addWeight=(weight-0.5)*2
    //計費計算方式寫在這，因為客戶編號就已經判定國別，所以不用再判斷
    let totalFee=allValues.price[nation]['basic']+allValues.price[nation]['add']* addWeight   //首重加續重
    
    setAllValues({ ...allValues, 
                   calculateResult: totalFee 
                })
  
  }

  return (
          <>
           
          <Helmet>
                <title>價目表</title>
          </Helmet>
          <PlasmicPrice 
                  
                   //下面是決定寄送國家
                  nationSelection={{
                    render:function MyComponent() {
                      return(
                            <Form  style={{ 'margin-top': '15px' }}>
                                <Form.Group>
                                    <Form.Field
                                      control={Radio}
                                      label='香港'
                                      value='HK'
                                      checked={allValues.nation === 'HK'}
                                      onClick={nationChange}
                                      style={{ 'font-size': '18px' ,'font-weight': 'bold'}}
                                    />
                                    <Form.Field
                                      control={Radio}
                                      label='澳門'
                                      value='MO'
                                      checked={allValues.nation === 'MO'}
                                      onClick={nationChange}
                                      style={{ 'font-size': '18px' ,'font-weight': 'bold' }}
                                    />
                                    <Form.Field
                                      control={Radio}
                                      label='新加坡'
                                      value='SG'
                                      checked={allValues.nation === 'SG'}
                                      onClick={nationChange}
                                      style={{ 'font-size': '18px' ,'font-weight': 'bold'}}
                                    />
                                    
                                  </Form.Group>
                                  
                            </Form>
                    )}
                  }}

                  weightNumber={{
                    name:"weightNumber",
                    value: allValues.weightNumber,
                    onChange: changeHandler,
                  }}

                  feeCalculate={{
                    name:"feeCalculate",
                    onClick: feeCalculateHandler,
                  }}

                  calculateResult={{
                    render:function MyComponent() {
                      if(allValues.calculateResult!== undefined){
                          let  nation= '' 
                          if(allValues.nation==='HK'){
                                   nation ='香港'
                          }else if(allValues.nation==='MO'){
                            nation ='澳門'
                          }else if (allValues.nation==='SG'){
                            nation ='新加坡'
                          }

                          return(
                             '寄送'+nation+' NTD$'+allValues.calculateResult
                                )
                      }else{
                          return(
                            'NTD$'+'0'
                          )
                      }
                    }
                  }}




                  hkPrice={{
                    render: function MyComponent() {
                      return (
                      <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#e76f51','textAlign': 'center'  }}>
                        <Table.Header >

                          <Table.Row >
                            <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#ffc300' }}>重量</Table.HeaderCell>
                            <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#ffc300' }}>計價</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body style={{ fontSize: '16px','font-weight':'bold' }}>
                          <Table.Row>
                            <Table.Cell>首重-0.5Kg</Table.Cell>
                            <Table.Cell>{allValues.price['HK']['basic']}</Table.Cell>

                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>續重-0.5Kg</Table.Cell>
                            <Table.Cell>{allValues.price['HK']['add']}</Table.Cell>
                          </Table.Row>
                          
                        </Table.Body>
                      </Table>
                    )}
                  }}

                  moPrice={{
                    render: function MyComponent() {
                      return (
                        <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#f4a261','textAlign': 'center'  }}>
                        <Table.Header >

                          <Table.Row >
                            <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#ffc300' }}>重量</Table.HeaderCell>
                            <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#ffc300' }}>計價</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body style={{ fontSize: '16px','font-weight':'bold' }}>
                          <Table.Row>
                            <Table.Cell>首重-0.5Kg</Table.Cell>
                            <Table.Cell>{allValues.price['MO']['basic']}</Table.Cell>

                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>續重-0.5Kg</Table.Cell>
                            <Table.Cell>{allValues.price['MO']['add']}</Table.Cell>
                          </Table.Row>
                          
                        </Table.Body>
                      </Table>
                    )}
                  }}

                  sgPrice={{
                    render:function MyComponent() {
                      return(
                        <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#e9c46a','textAlign': 'center'  }}>
                        <Table.Header >

                          <Table.Row >
                            <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#ffc300' }}>重量</Table.HeaderCell>
                            <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#ffc300' }}>計價</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body style={{ fontSize: '16px','font-weight':'bold' }}>
                          <Table.Row>
                            <Table.Cell>首重-0.5Kg</Table.Cell>
                            <Table.Cell>{allValues.price['SG']['basic']}</Table.Cell>

                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>續重-0.5Kg</Table.Cell>
                            <Table.Cell>{allValues.price['SG']['add']}</Table.Cell>
                          </Table.Row>
                          
                        </Table.Body>
                      </Table>

                    )}
                  }}

                  hkPriceDeskTop={{
                    render: function MyComponent() {
                      return(
                      <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#ffdd00','text-align': 'center'  }}>
                        <Table.Header >

                          <Table.Row >
                            <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '44px', 'backgroundColor': '#ffc300' }}>重量</Table.HeaderCell>
                            <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '44px', 'backgroundColor': '#ffc300' }}>運費總價</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body style={{ fontSize: '40px' }}>
                          <Table.Row>
                            <Table.Cell>首重-1Kg</Table.Cell>
                            <Table.Cell>200</Table.Cell>

                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>2Kg</Table.Cell>
                            <Table.Cell>300</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>3Kg</Table.Cell>
                            <Table.Cell>400</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>4Kg</Table.Cell>
                            <Table.Cell>500</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>5Kg</Table.Cell>
                            <Table.Cell>600</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>6Kg</Table.Cell>
                            <Table.Cell>700</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>7Kg</Table.Cell>
                            <Table.Cell>800</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>8Kg</Table.Cell>
                            <Table.Cell>900</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            {/* 以上=>首重+150*公斤數 */}
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    )}
                  }}

                  moPriceDeskTop={{
                    render: function MyComponent() {
                      return (
                      <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#ffdd00' ,'text-align': 'center' }}>
                        <Table.Header >

                          <Table.Row >
                            <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '44px', 'backgroundColor': '#ffc300' }}>重量</Table.HeaderCell>
                            <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '44px', 'backgroundColor': '#ffc300' }}>運費總價</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body style={{ fontSize: '40px' }}>
                          <Table.Row>
                            <Table.Cell>首重-1Kg</Table.Cell>
                            <Table.Cell>200</Table.Cell>

                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>2Kg</Table.Cell>
                            <Table.Cell>300</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>3Kg</Table.Cell>
                            <Table.Cell>400</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>4Kg</Table.Cell>
                            <Table.Cell>500</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>5Kg</Table.Cell>
                            <Table.Cell>600</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>6Kg</Table.Cell>
                            <Table.Cell>700</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>7Kg</Table.Cell>
                            <Table.Cell>800</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>8Kg</Table.Cell>
                            <Table.Cell>900</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                              {/* 以上=>首重+150*公斤數 */}
                            </Table.Row>
                        </Table.Body>
                      </Table>
                    )}
                  }}

                  sgPriceDeskTop={{
                    render:function MyComponent() {
                      return(
                      <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#ffdd00' ,'text-align': 'center' }}>
                        <Table.Header >

                          <Table.Row >
                            <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '44px', 'backgroundColor': '#ffc300' }}>重量</Table.HeaderCell>
                            <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '44px', 'backgroundColor': '#ffc300' }}>運費總價</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body style={{ fontSize: '40px' }}>
                          <Table.Row>
                            <Table.Cell>首重-1Kg</Table.Cell>
                            <Table.Cell>200</Table.Cell>

                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>2Kg</Table.Cell>
                            <Table.Cell>300</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>3Kg</Table.Cell>
                            <Table.Cell>400</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>4Kg</Table.Cell>
                            <Table.Cell>500</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>5Kg</Table.Cell>
                            <Table.Cell>600</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>6Kg</Table.Cell>
                            <Table.Cell>700</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>7Kg</Table.Cell>
                            <Table.Cell>800</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>8Kg</Table.Cell>
                            <Table.Cell>900</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                              {/* 以上=>首重+150*公斤數 */}
                            </Table.Row>
                        </Table.Body>
                      </Table>

                    )}
                  }}
         />
         </>
         )
}

export default Price;